<template>
  <div>
    <PillTabs
      :selected="selectedTab"
      :tabs="tabs"
      @update="selectedTab = $event"
    />

    <Alert v-if="$vuetify.breakpoint.smAndDown" color="warning">
      <b>ATENÇÃO:</b> a funcionalidade de arrastar e solta pode não estar
      funcionando adequadamente na versão mobile. Recomendamos que a edição de
      modelos seja feita em um computador desktop.
    </Alert>

    <v-text-field
      v-model="search"
      solo
      background-color="grey lighten-4"
      elevation="0"
      preprend-inner-icon="ph-magnifying-glass"
      label="Pesquisar TAG"
      hide-details
      class="mb-4 elevation-0 mx-2"
      flat
    />

    <v-card flat tile class="transparent overflow-y-auto">
      <draggable
        v-if="tags.length > 0"
        class="tags-drag-area"
        :list="tags"
        :group="{
          name: selectedGroupToClone,
          pull: 'clone',
          put: false,
        }"
        :clone="handleClone"
        style="width: 100%"
      >
        <div v-for="({ name, tag }, index) in tags" :key="index">
          <v-card tile outlined class="mb-4">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon v-text="'mdi-cursor-move'" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-medium text-wrap break-word"
                    >
                      {{ name }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="caption">
                      {{ tag }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <span>Segure e arraste o elemento para a área de assinatura</span>
            </v-tooltip>
          </v-card>
        </div>
      </draggable>

      <v-card-title
        v-else-if="search && tags.length === 0"
        class="text-wrap break-word font-weight-regular text-center"
      >
        Nenhum resultado encontrado para
        <span class="font-weight-bold">{{ search }}</span>
      </v-card-title>
    </v-card>
  </div>
</template>
<script>
import PillTabs from "@/components/general/PillTabs.vue";
import draggable from "vuedraggable";

import {
  disabledCompanyTags,
  editableCompanyTags,
  userDisabledTags,
  userEditableTags,
} from "@/helpers/variables/tags";

import { getHtmlFromObject } from "@/helpers/services/tagPreview";
import { mapGetters } from "vuex";

export default {
  name: "TagsDrgInDrop",

  components: { draggable, PillTabs },

  data() {
    return {
      search: "",
      banner: true,
      selectedTab: 0,
      tabs: [
        {
          icon: "ph-bold ph-text-t",
          text: "Texto",
          value: "text",
        },
        {
          icon: "ph-link-simple-horizontal",
          text: "Links",
          value: "links",
        },
        {
          icon: "ph-image",
          text: "Imagem",
          value: "image",
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["signatureStructure", "currentUser", "company"]),

    tags() {
      const tags = {
        ...disabledCompanyTags,
        ...editableCompanyTags,
        ...userDisabledTags,
        ...userEditableTags,
      };

      return Object.entries(tags)
        .reduce((acc, tagInfo) => {
          if (tagInfo[1].type == this.filterBy) {
            const element = {
              value: tagInfo[1].label,
              styles: "",
            };

            acc.push({
              tag: element.value,
              value: getHtmlFromObject(element, this.currentUser, this.company),
              name: tagInfo[1].keyName,
              type: tagInfo[1].type,
              styles: "",
            });
          }

          return acc;
        }, [])
        .filter((item) =>
          this.search
            ? item.value.toLowerCase().includes(this.search.toLowerCase()) ||
              item.name.toLowerCase().includes(this.search.toLowerCase())
            : item
        );
    },

    filterBy() {
      if (this.isText) {
        return "text";
      } else if (this.isLink) {
        return "url";
      } else if (this.isImage) {
        return "image";
      } else {
        return "";
      }
    },

    isText() {
      return this.selectedTab === 0;
    },

    isImage() {
      return this.selectedTab === 2;
    },

    isLink() {
      return this.selectedTab === 1;
    },

    selectedGroupToClone() {
      const imageSelected = this.selectedTab === 2;
      const centerStructureSelected = this.signatureStructure === "center";

      if (imageSelected) {
        return !centerStructureSelected ? "image" : "tags";
      }
      return "tags";
    },
  },

  methods: {
    handleClone(item) {
      // Create a fresh copy of item
      let cloneMe = JSON.parse(JSON.stringify(item.value));

      return cloneMe;
    },

    uuid(e) {
      if (e.uid) return e.uid;

      const key = Math.random().toString(16).slice(2);

      this.$set(e, "uid", key);

      return e.uid;
    },
  },
};
</script>
<style>
.tags-drag-area ul,
.tags-drag-area li {
  list-style-type: none;
  cursor: move;
}

.tags-drag-area li:hover {
  background-color: rgb(252, 255, 220);
  cursor: grab !important;
}

.tags-drag-area li {
  padding: 7px;
  margin-bottom: 10px;
  background-color: rgb(241, 241, 241);
}
</style>
