export const customButtonDefault = {
  button_style: "filled",
  font_size: 16,
  align: "start",
  text: "Texto do botão",
  link: "",
  background_color: "#CCCCCC",
  text_color: "#000000",
  format: "0px",
  letter_transform: "none",
  letter_spacing: 0,
  size: "medium",
  shadow_box: 0,
  icon_color: "black",
  icon_align: "left",
  custom_icon_url: "",
  right_arrow: null,
  borders: [],
  border_style: "solid",
  border_color: "#000000",
  border_size: 0,
  text_decoration: "text-decoration:none!important;",
};

export const buttonFormats = [
  {
    name: "Quadrado",
    value: "0px",
    class: "rounded-0",
  },
  {
    name: "Arredondado",
    value: "10px",
    class: "rounded-md",
  },
  {
    name: "Circular",
    value: "40px",
    class: "rounded-pill",
  },
];

export const buttonSize = [
  {
    name: "P",
    value: "small",
  },
  {
    name: "M",
    value: "medium",
  },
  {
    name: "G",
    value: "large",
  },
];

export const letterFormats = [
  {
    name: "Normal",
    icon: "mdi-format-letter-case",
    tip: "Normal",
    value: "none",
  },
  {
    name: "Maiúsculas",
    icon: "mdi-format-letter-case-upper",
    tip: "Todas as letras maiúsculas",
    value: "uppercase",
  },
  {
    name: "Minúsculas",
    icon: "mdi-format-letter-case-lower",
    tip: "Todas as letras minúsculas",
    value: "lowercase",
  },
];

export const iconColors = [
  {
    name: "Branco",
    value: "white",
    class: "white",
    text_class: "black",
  },
  {
    name: "Preto",
    value: "black",
    class: "black",
    text_class: "white",
  },
  {
    name: "Cinza claro",
    value: "light_grey",
    class: "grey lighten-1",
    text_class: "black",
  },
  /* TODO: chavear e recuperar as imagens já presentes no Google Drive para esta cor */
  /* {
    name: "Cinza escuro",
    value: "dark_grey",
    class: "grey darken-2",
    text_class: "white",
  }, */
];

export const buttonBorder = [
  {
    name: "Esquerda",
    icon: "mdi-border-left-variant",
    value: "border-left",
  },
  {
    name: "Direita",
    icon: "mdi-border-right-variant",
    value: "border-right",
  },
  {
    name: "Abaixo",
    icon: "mdi-border-bottom-variant",
    value: "border-bottom",
  },
  {
    name: "Acima",
    icon: "mdi-border-top-variant",
    value: "border-top",
  },
  /* {
    name: "Sem bordas",
    icon: "mdi-border-none-variant",
    value: null,
  }, */
];

export const buttonStyles = [
  {
    name: "Preenchido",
    icon: "mdi-rectangle",
    value: "filled",
  },
  {
    name: "Somente borda",
    icon: "mdi-rectangle-outline",
    value: "outline",
  },
  {
    name: "Somente texto",
    icon: "ph-bold ph-text-t",
    value: "link",
  },
];

export const borderStyles = [
  {
    name: "Linha",
    value: "solid",
  },
  {
    name: "Tracejado",
    value: "dashed",
  },
  {
    name: "Pontilhada",
    value: "dotted",
  },
];

export const buttonIconAlignments = [
  {
    name: "À esquerda",
    icon: "mdi-align-horizontal-left",
    value: "left",
  },
  {
    name: "À direita",
    icon: "mdi-align-horizontal-right",
    value: "right",
  },
];

export const textDecorations = [
  {
    name: "Sublinhado",
    icon: "ph-bold ph-text-underline",
    value: "text-decoration:underline!important;",
  },
  {
    name: "Tachado",
    icon: "mdi-format-strikethrough-variant",
    value: "text-decoration:line-through!important;",
  },
];
