<template>
  <div>
    <v-row align="center" v-if="!hideTabs">
      <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="6" lg="9">
        <SignatureInsightsDialog />
      </v-col>

      <v-col cols="12" md="6" lg="3">
        <div :class="$vuetify.breakpoint.mdAndUp ? `d-flex justify-end` : ''">
          <v-tabs
            background-color="transparent"
            class="mt-4"
            slider-size="4"
            v-model="selectedTab"
            right
          >
            <v-tab
              class="text-none text-body-1 font-weight-medium"
              v-for="({ title }, index) in tabs"
              :key="index"
            >
              {{ title }}
            </v-tab>
          </v-tabs>
        </div>
      </v-col>
    </v-row>

    <v-subheader v-if="hideTabs" class="font-weight-medium">
      Editor HTML
    </v-subheader>

    <div v-if="hideTabs">
      <Alert color="warning" class="mb-4">
        <span>
          <b>VERSÃO BETA</b> - Algumas funções deste editor podem não estar
          funcionando adequadamente.
        </span>

        <span v-if="isConecta">
          Volte à versão estável clicando em "Opções do editor"
          <v-icon v-text="'ph-arrow-right'" small /> "Voltar para versão
          anterior" no menu superior direito.
        </span>

        <span v-else>
          Volte à versão estável clicando em "Opções do editor"
          <v-icon v-text="'ph-arrow-right'" small /> "Editor Rich Text" no menu
          superior direito.
        </span>
      </Alert>

      <slot />
    </div>

    <v-tabs-items v-else v-model="selectedTab" class="pa-0 mt-0 rounded-tr-0">
      <v-tab-item class="pa-0 transparent ma-0">
        <Alert color="warning" class="ma-4">
          <span>
            <b>VERSÃO BETA</b> - Algumas funções deste editor podem não estar
            funcionando adequadamente.
          </span>

          <span v-if="isConecta">
            Volte à versão estável clicando em "Opções do editor"
            <v-icon v-text="'ph-arrow-right'" small /> "Voltar para versão
            anterior" no menu superior direito.
          </span>

          <span v-else>
            Volte à versão estável clicando em "Opções do editor"
            <v-icon v-text="'ph-arrow-right'" small /> "Editor Rich Text" no
            menu superior direito.
          </span>
        </Alert>
        <slot />
      </v-tab-item>

      <v-tab-item class="pt-0 pb-4 px-4 transparent">
        <HtmlEditor
          id="editor-html"
          :html="selectedTemplateNewHtml"
          readonly
          :height="'45vh'"
          class="my-4"
        />

        <v-card-actions class="justify-end px-0">
          <v-btn
            dark
            large
            color="primary"
            class="text-none text-body-2 font-weight-medium px-4"
            @click="copyHtml"
          >
            <v-icon v-text="'ph-code'" left />
            Copiar HTML
          </v-btn>
        </v-card-actions>
      </v-tab-item>
    </v-tabs-items>

    <v-subheader :class="`text-body-2 text--darken-1 font-italic px-0`">
      {{ htmlSize }}/9000 caracteres
    </v-subheader>

    <v-row class="py-4 transparent">
      <v-col>
        <TemplatePreview
          :key="htmlPreview"
          :html="htmlPreview"
          :hide-bottom="true"
          :hide-top="true"
          :info-to-render="currentUser"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import TemplatePreview from "@/components/sign/general/TemplatePreview";
import SignatureInsightsDialog from "@/components/sign/new_users_signature/SignatureInsightsDialog";
import HtmlEditor from "@/components/sign/signature/HtmlEditor.vue";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "EditorTabs",

  props: {
    hideTabs: { type: Boolean, default: false },
    html: { type: String, default: "" },
  },

  components: { HtmlEditor, SignatureInsightsDialog, TemplatePreview },

  data() {
    return {
      selectedTab: null,
      /* htmlPreview: "", */
      tabs: [
        {
          title: "Editor",
        },
        {
          title: "HTML",
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["currentUser", "selectedTemplateNewHtml", "isConecta"]),

    htmlPreview() {
      return this.setPreviewHtml();
    },

    htmlSize() {
      return this.selectedTemplateNewHtml
        ? this.selectedTemplateNewHtml.length
        : 0;
    },
  },

  watch: {
    html() {
      this.setPreviewHtml();
    },
  },

  methods: {
    ...mapMutations(["setSnackBar"]),

    copyHtml() {
      try {
        navigator.clipboard.writeText(this.html);

        this.setSnackBar({
          show: true,
          color: "success",
          message: "Código html copiado",
        });
      } catch (error) {
        this.setSnackBar({
          show: true,
          color: "error",
          message: "Erro ao tentar copiar assinatura html",
        });

        console.error("copyHtml(): ", error);
      }
    },

    setPreviewHtml() {
      return this.selectedTemplateNewHtml.replace("max-width:800px", "");
    },
  },

  mounted() {
    this.setPreviewHtml();
  },
};
</script>
