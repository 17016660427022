<template>
  <v-container>
    <v-toolbar flat class="mx-0 mb-4" dense>
      <v-autocomplete
        label="Roboto"
        disabled
        hide-details
        solo
        flat
        dense
        style="max-width: 250px"
      />
      <v-autocomplete
        label="Título 1"
        disabled
        hide-details
        solo
        flat
        dense
        style="max-width: 250px"
      />

      <v-divider vertical class="ml-1 mr-2" />

      <v-autocomplete
        label="TAGs"
        disabled
        hide-details
        solo
        flat
        dense
        style="max-width: 250px"
      />

      <v-btn icon tile disabled class="mx-2">
        <v-icon v-text="'ph-link-simple-horizontal'" />
      </v-btn>

      <v-btn icon tile disabled>
        <v-icon v-text="'ph-image'" />
      </v-btn>

      <v-chip color="warning"> Em breve </v-chip>
    </v-toolbar>

    <HtmlEditor
      ref="htmlEditor"
      :placeholder="'Insira o código da sua assinatura HTML aqui'"
      :html="updatedHtml"
      :height="'400px'"
      :disabled="disabled"
      @update="updatedHtml = $event"
    />
  </v-container>
</template>
<script>
import HtmlEditor from "@/components/sign/signature/HtmlEditor.vue";

import { hasChanges } from "@/helpers/services/utils";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "HtmlSimpleEditor",

  props: {
    disabled: { type: Boolean, default: false },
  },

  components: {
    HtmlEditor,
  },

  computed: {
    ...mapGetters(["selectedTemplateNewHtml"]),

    updatedHtml: {
      get() {
        return this.selectedTemplateNewHtml;
      },
      set(new_html) {
        if (hasChanges(this.selectedTemplateNewHtml, new_html)) {
          this.updateSelectedTemplateHtml(new_html);
        }
      },
    },
  },

  methods: {
    ...mapMutations(["updateSelectedTemplateHtml"]),

    // TODO: Essa função ainda não está funcionando, no futuro, verificar como faze-la funcionar para inserir conteúdo na última posição do cursor
    insertContent(insert) {
      const self = this;
      var tArea = this.$refs.htmlEditor;

      // filter:
      if (0 == insert) {
        return;
      }
      if (0 == cursorPos) {
        return;
      }

      // get cursor's position:
      var startPos = tArea.selectionStart,
        endPos = tArea.selectionEnd,
        cursorPos = startPos,
        tmpStr = tArea.editedHtml;

      // insert:
      self.txtContent =
        tmpStr.substring(0, startPos) +
        insert +
        tmpStr.substring(endPos, tmpStr.length);

      // move cursor:
      setTimeout(() => {
        cursorPos += insert.length;
        tArea.selectionStart = tArea.selectionEnd = cursorPos;
      }, 10);
    },
  },

  mounted() {
    document.getElementsByClassName("prism-editor__textarea")[0].focus();
  },
};
</script>
