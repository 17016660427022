<template>
  <v-toolbar dense flat class="px-0 mx-0">
    <EditImageDialog
      :styles="styles"
      :element="element"
      :tag="tag"
      @update="emitChanges"
    />

    <v-spacer />

    <v-btn icon tile @click="$emit('remove')">
      <v-icon v-text="'ph-trash'" />
    </v-btn>
  </v-toolbar>
</template>
<script>
import EditImageDialog from "@/components/forms/inputs/EditImageDialog.vue";
import { hasChanges } from "@/helpers/services/utils";

export default {
  name: "ImageToolbarMenu",

  props: {
    element: { type: String, default: "" },

    styles: { type: String, default: "" },

    tag: { type: String, default: "" },
  },

  components: { EditImageDialog },

  data() {
    return {
      imageAlign: "left",
    };
  },

  watch: {
    imageValues(newVal, oldVal) {
      if (hasChanges(newVal, oldVal)) {
        this.$emit("update", this.imageValues);
      }
    },
  },

  methods: {
    emitChanges(newHtml) {
      if (hasChanges(this.element, newHtml)) {
        this.$emit("update", newHtml);
      }
    },
  },
};
</script>
