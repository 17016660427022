<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        tile
        color="secondary lighten-1"
        class="text-none text-body-1 font-weight-medium mx-0"
        text
        v-bind="attrs"
        v-on="on"
        @click="dialog = !dialog"
      >
        <v-icon v-text="'ph-gear'" left size="22" />
        Configurar imagem
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 mb-3"> Editar imagem</v-card-title>

      <v-form v-model="valid" class="px-3">
        <v-row class="ma-0 pa-0" align="center">
          <v-col cols="6" class="py-1">
            <v-text-field
              v-model="imageWidth"
              label="Lagura"
              filled
              :hide-details="valid"
              :rules="[required, biggerThanZero]"
            />
          </v-col>
          <v-col cols="6" class="py-1">
            <v-text-field
              v-model="imageHeight"
              label="Altura"
              filled
              :hide-details="valid"
              :rules="[ratioRule]"
            />
          </v-col>

          <v-col cols="12" class="pt-0">
            <v-checkbox
              v-model="ratio"
              dense
              hide-details
              label="Manter proprorção da imagem"
            />
          </v-col>

          <v-col cols="12" class="py-4">
            <v-select
              v-model="imageAlign"
              :items="imageAlignOptions"
              label="Alinhamento da imagem"
              filled
              hide-details
            />
          </v-col>

          <v-col cols="12" class="pt-4 pb-1">
            <v-select
              v-model="border.border_radius"
              :items="borderRadius"
              label="Estilo da borda"
              filled
              hide-details
            />
          </v-col>

          <!-- TODO: ADICIONAR FUTURAMENTE POSSIBILIDADE DE INSERIR LINK -->
        </v-row>
      </v-form>

      <v-card-actions class="mt-5">
        <v-row class="ma-0 pa-0">
          <v-col
            :order="$vuetify.breakpoint.sm ? 2 : 1"
            cols="12"
            md="6"
            sm="12"
            :class="`${$vuetify.breakpoint.sm ? 'd-flex justify-center' : ''}`"
          >
            <v-btn
              color="secondary lighten-2"
              class="text-none text-body-1 font-weight-medium px-0"
              text
              @click="cancel"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col
            :order="$vuetify.breakpoint.sm ? 1 : 2"
            cols="12"
            md="6"
            sm="12"
            :class="`${
              $vuetify.breakpoint.sm
                ? 'd-flex justify-center'
                : 'd-flex justify-end'
            }`"
          >
            <v-btn
              class="text-none text-body-1 font-weight-medium px-6"
              color="primary"
              :disabled="!valid"
              @click="saveImageSettings"
            >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
/* import LinkInput from "@/components/forms/inputs/LinkInput"; */

import {
  getDivAlign,
  getImageBorderRadius,
  getImageHeight,
  getImageSrc,
  getImageTag,
  getImageWidth,
} from "@/helpers/services/tagPreview";

export default {
  name: "EditImageDialog",

  components: {},

  props: {
    element: { type: String, default: "" },

    openNewTab: { type: Boolean, default: false },
    link: { type: String, default: "" },
    text: { type: String, default: "" },

    styles: { type: String, default: "" },

    tag: { type: String, default: "" },
  },

  data() {
    return {
      biggerThanZero: (e) =>
        parseInt(e) > 0 || "Largura precisa ser maior que 0",

      border: {
        border_radius: "",
      },

      borderRadius: [
        {
          text: "Sem bordas",
          value: "",
        },
        {
          text: "Arredondado",
          value: "30%",
        },
        {
          text: "Redondo",
          value: "100%",
        },
      ],

      dialog: false,

      elementTag: "",

      imageAlign: "",

      imageAlignOptions: [
        {
          text: "À esquerda",
          value: "",
        },
        {
          text: "Centralizada",
          value: "text-align:center",
        },
        {
          text: "À direita",
          value: "text-align:right",
        },
      ],

      imageHeight: 200,
      imageSrc: "",
      imageWidth: 200,

      linkIsValid: true,

      localLink: "",
      localText: "",

      newTabLink: false,

      originalWidth: 200,
      originalHeight: 200,

      ratio: true,

      ratioRule: (e) =>
        (!!e && (parseInt(e) > 0 || e === "auto")) ||
        "Somente números maiores que 0 ou opção 'auto'",

      required: (e) => !!e || "Campo obrigatório",

      valid: true,
    };
  },

  watch: {
    element() {
      this.reset();
      this.setElementStyles();
    },

    dialog() {
      this.reset();
      this.setElementStyles();
    },

    imageWidth(newVal, oldVal) {
      if (this.ratio) {
        if (oldVal === this.imageHeight) {
          this.imageHeight = this.imageWidth;
        } else {
          this.imageHeight = "auto";
        }
      }
    },

    link() {
      this.localLink = this.link;
    },

    openNewTab() {
      this.newTabLink = this.openNewTab;
    },

    text() {
      this.localText = this.text;
    },
  },

  methods: {
    cancel() {
      this.clear();
      this.dialog = false;
    },

    clear() {
      this.newTabLink = false;
      this.linkIsValid = false;
      this.localLink = "";
      this.localText = "";
    },

    reset() {
      this.originalWidth = getImageWidth(this.element);
      this.originalHeight = getImageHeight(this.element);

      this.imageWidth = getImageWidth(this.element);
      this.imageHeight = getImageHeight(this.element);
      this.imageSrc = getImageSrc(this.element);

      const alignment = getDivAlign(this.element);

      this.imageAlign = alignment ? `text-align:${alignment}` : "";
    },

    saveImageSettings() {
      const image = `<img 
                        src="${this.elementTag}" 
                        width="${this.imageWidth}" 
                        height="${this.imageHeight}" 
                        alt="${this.elementTag}"
                        style="${
                          this.border.border_radius
                            ? "border-radius:" + this.border.border_radius
                            : ""
                        }"
                      />`;

      if (this.imageAlign) {
        const alignedDiv = `<div style="${this.imageAlign}!important">${image}</div>`;

        this.$emit("update", alignedDiv);
      } else {
        this.$emit("update", image);
      }

      this.dialog = false;
    },

    setElementStyles() {
      if (this.element) {
        this.border.border_radius = getImageBorderRadius(this.element) || "";

        this.elementTag = getImageTag(this.element);
      }
    },
  },

  beforeMount() {
    this.reset();
    this.setElementStyles();
  },
};
</script>
