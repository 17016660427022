var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showStartModelTip)?_c('v-tour',{attrs:{"name":"startModelTip","steps":_vm.steps,"options":_vm.tourOptions,"callbacks":_vm.myCallbacks}}):_vm._e(),(_vm.inline)?_c('v-subheader',{staticClass:"font-weight-medium px-3",attrs:{"id":"v-step-99"}},[_vm._v(" Selecione uma estrutura para seu modelo ")]):_vm._e(),_c('v-item-group',{staticClass:"pa-0 ma-0",attrs:{"mandatory":""},model:{value:(_vm.selectedStructure),callback:function ($$v) {_vm.selectedStructure=$$v},expression:"selectedStructure"}},[_c('v-card',{class:`pa-0 overflow-y-auto overflow-x-hidden`,attrs:{"flat":"","tile":"","height":"300"}},[_c('v-row',{class:`ma-0 pa-0`,attrs:{"align":"center","justify":"center"}},_vm._l((_vm.defaultStructures),function(structure,i){return _c('v-col',{key:i,class:`${_vm.inline ? '' : 'px-0'}`,attrs:{"cols":_vm.inline ? 6 : 12}},[_c('v-item',{attrs:{"value":structure.value}},[_c('v-card',{class:`${
                _vm.inline ? '' : 'mx-auto'
              } d-flex justify-center align-center rounded-lg ${
                _vm.isSelected(structure.value) && _vm.inline
                  ? 'selected-structure'
                  : ''
              }`,attrs:{"max-width":_vm.inline ? '100%' : 170,"outlined":"","hover":"","height":"120"},on:{"click":function($event){return _vm.emitStructure(structure)}}},[(_vm.isSelected(structure.value) && _vm.inline)?_c('v-sheet',{staticClass:"rounded-circle pa-1 selected-structure-icon d-flex justify-center",staticStyle:{"z-index":"200"},attrs:{"color":"primary","height":40,"width":40}},[_c('v-icon',{attrs:{"dark":"","size":"28"},domProps:{"textContent":_vm._s('ph-check')}})],1):_vm._e(),_c('v-card-text',{class:`${
                  _vm.isSelected(structure.value) && _vm.inline
                    ? 'selected-structure-overlay'
                    : ''
                } image-without-background`},[_c('v-img',{attrs:{"src":structure.preview,"alt":structure.name,"width":"100%","contain":""}})],1)],1)],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }