<template>
  <div>
    <v-tour
      v-if="showStartModelTip"
      name="startModelTip"
      :steps="steps"
      :options="tourOptions"
      :callbacks="myCallbacks"
    >
    </v-tour>

    <v-subheader v-if="inline" class="font-weight-medium px-3" id="v-step-99">
      Selecione uma estrutura para seu modelo
    </v-subheader>

    <v-item-group v-model="selectedStructure" mandatory class="pa-0 ma-0">
      <v-card
        flat
        tile
        :class="`pa-0 overflow-y-auto overflow-x-hidden`"
        height="300"
      >
        <v-row align="center" justify="center" :class="`ma-0 pa-0`">
          <v-col
            :cols="inline ? 6 : 12"
            :class="`${inline ? '' : 'px-0'}`"
            v-for="(structure, i) in defaultStructures"
            :key="i"
          >
            <v-item :value="structure.value">
              <v-card
                @click="emitStructure(structure)"
                :class="`${
                  inline ? '' : 'mx-auto'
                } d-flex justify-center align-center rounded-lg ${
                  isSelected(structure.value) && inline
                    ? 'selected-structure'
                    : ''
                }`"
                :max-width="inline ? '100%' : 170"
                outlined
                hover
                height="120"
              >
                <v-sheet
                  v-if="isSelected(structure.value) && inline"
                  class="rounded-circle pa-1 selected-structure-icon d-flex justify-center"
                  color="primary"
                  :height="40"
                  :width="40"
                  style="z-index: 200"
                >
                  <v-icon class="" v-text="'ph-check'" dark size="28" />
                </v-sheet>

                <v-card-text
                  :class="`${
                    isSelected(structure.value) && inline
                      ? 'selected-structure-overlay'
                      : ''
                  } image-without-background`"
                >
                  <v-img
                    :src="structure.preview"
                    :alt="structure.name"
                    width="100%"
                    contain
                  />
                </v-card-text>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-card>
    </v-item-group>
  </div>
</template>
<script>
import {
  preTemplates,
  preTemplatesDragAndDrop,
} from "@/helpers/variables/templates";
import { mapGetters } from "vuex";

export default {
  name: "SignatureStructures",

  props: {
    inline: { type: Boolean, default: false },
    showStartTip: { type: Boolean, default: false },
  },

  data() {
    return {
      preTemplates,
      preTemplatesDragAndDrop,
      selectedStructure: "left",
      myCallbacks: {
        onSkip: this.setTourFalse,
        onFinish: this.setTourFalse,
        onStop: this.setTourFalse,
      },
      showStartModelTip: false,
    };
  },

  computed: {
    ...mapGetters(["signatureStructure"]),

    steps() {
      return [
        {
          show: true,
          target: "#v-step-99",
          header: {
            title: this.$t("tour.vstep99Title"),
          },
          content: this.$t("tour.vstep99"),
        },
      ];
    },

    tourLabels() {
      return {
        buttonStop: this.$t("common.understood"),
      };
    },
    defaultStructures() {
      return [
        {
          name: "Modelo à esquerda",
          preview: require("@/assets/templates/template_1.png"),
          html: preTemplates.left,
          value: "left",
        },
        {
          name: "Modelo à direita",
          preview: require("@/assets/templates/template_2.png"),
          html: preTemplates.right,
          value: "right",
        },
        {
          name: "Modelo centralizado",
          preview: require("@/assets/templates/template_3.png"),
          html: preTemplates.center,
          value: "center",
        },
        {
          name: "Modelo com logo à esquerda",
          preview: require("@/assets/templates/template_4.png"),
          html: preTemplates.logoLeft,
          value: "logoLeft",
        },
        {
          name: "Modelo com logo abaixo",
          preview: require("@/assets/templates/template_5.png"),
          html: preTemplates.logoBottom,
          value: "logoBottom",
        },
        {
          name: "Modelo com foto de perfil à esquerda e logo abaixo",
          preview: require("@/assets/templates/template_6.png"),
          html: preTemplates.profileLogoBottom,
          value: "profileLogoBottom",
        },
      ];
    },

    tourOptions() {
      return {
        highlight: false,
        labels: this.tourLabels,
      };
    },
  },

  methods: {
    emitStructure(structure) {
      this.selectedStructure = structure.value;

      if (this.inline) {
        this.$emit("set-structure", structure.value);
      } else {
        this.$emit("set-structure", structure.html);
      }
    },

    isSelected(value) {
      return this.selectedStructure === value;
    },

    setTourFalse() {
      if (this.showStartModelTip) {
        localStorage.setItem("showStartModelTip", "false");
        this.showStartModelTip = false;
      }
    },
  },

  beforeMount() {
    this.selectedStructure = this.signatureStructure;

    if (this.showStartTip) {
      this.showStartModelTip = true;
      localStorage.setItem("showStartModelTip", "true");
    }
  },

  beforeDestroy() {
    if (this.showStartTip) {
      this.$tours["startModelTip"].stop();
    }
  },

  mounted() {
    if (this.showStartTip) {
      this.$tours["startModelTip"].start();
    }
  },
};
</script>

<style>
.selected-structure {
  border: 4px solid var(--v-primary-base) !important;
}
.selected-structure-overlay {
  opacity: 0.3;
}
.selected-structure-icon {
  position: absolute;
  z-index: 400;
}

.image-without-background {
  mix-blend-mode: multiply;
}
</style>
