<template>
  <v-expansion-panel v-if="imagesWarningsCount > 0">
    <v-expansion-panel-header>
      <v-card-title class="px-0 error--text text-center pt-0 mt-0">
        Potenciais problemas identificados ({{ imagesWarningsCount }})
      </v-card-title>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="">
      <v-list-item
        two-line
        v-for="({ title, icon, status, subtitle }, index) in signatureDiagnosis"
        :key="index"
        class="px-0 mb-3"
      >
        <v-list-item-action class="mr-5">
          <v-avatar
            :color="status ? 'red lighten-4' : 'green lighten-4'"
            size="60"
          >
            <v-icon v-text="icon" :color="status ? 'error' : 'success'" />
          </v-avatar>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title
            class="text-wrap break-word font-weight-medium text-body-1"
          >
            {{ title }}
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="subtitle"
            class="mt-2 text-wrap break-word normal-line-height"
            v-html="subtitle"
          />

          <v-list-item-subtitle
            class="mt-2 text-wrap break-word normal-line-height font-weight-bold error--text"
          >
            {{ imagesWarningsCount }}
            {{
              imagesWarningsCount === 1
                ? "imagem de domínio externo detectada."
                : "imagens de domínio externo detectadas."
            }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import { mapGetters } from "vuex";

import { getExternalImages, hasExternalImage } from "@/helpers/services/images";

export default {
  name: "SignatureWarningsPanel",

  data() {
    return {
      externalImages: [],
      externalImagesCount: 0,
    };
  },

  computed: {
    ...mapGetters(["selectedTemplateNewHtml"]),

    signatureDiagnosis() {
      return [
        {
          status: this.imagesWarningsCount > 0,
          number: this.imagesWarningsCount,
          icon: "ph-magnifying-glass",
          title: "Imagem com domínio externo",
          subtitle: `Recomendamos que as <b>imagens inseridas em assinaturas sejam carregadas no Google Drive
            ou junto ao hospedador do seu site</b>, evitando assim que as mensagens possam cair na
            caixa de span.`,
        },
      ];
    },

    signatureTemplate() {
      return this.selectedTemplateNewHtml;
    },

    imagesWarningsCount() {
      return this.externalImagesCount;
    },

    images() {
      return this.externalImages;
    },
  },

  watch: {
    signatureTemplate() {
      this.reset();
    },
  },

  methods: {
    reset() {
      this.externalImagesCount = hasExternalImage(this.signatureTemplate);
      this.externalImages = getExternalImages(this.signatureTemplate);
    },
  },

  beforeMount() {
    this.reset();
  },
};
</script>
