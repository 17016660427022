<template>
  <HeaderDialog
    id="new-users-model"
    :title="$t('applySignature.defineModelToNewUsers')"
    color="primary"
    :show="showDialog"
    :showLoading="loading"
    width="700"
    showCancel
    button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
    @close="showDialog = false"
    @action="saveNewUsersTemplate(templateId)"
  >
    <template v-slot:body>
      <p
        class="text-body-1"
        v-html="$t('applySignature.defineModelToNewUsersDescription')"
      />

      <v-row
        align="center"
        justify="center"
        :class="`${$vuetify.breakpoint.xs ? 'flex-column' : ''}`"
      >
        <v-col xs="12" sm="2" class="text-center">
          <v-icon v-text="'ph-fill ph-user'" size="100" class="mx-auto" />
          <v-chip class="mx-auto font-weight-bold" x-small>
            {{ $t("applySignature.newUser") }}
          </v-chip>
        </v-col>
        <v-col xs="12" sm="2" class="text-center">
          <v-icon
            v-text="
              $vuetify.breakpoint.xs
                ? 'ph-bold ph-arrow-down'
                : 'ph-bold ph-arrow-right'
            "
            color="primary"
            size="70"
          />
        </v-col>
        <v-col xs="12" sm="5" class="text-center">
          <v-card outlined width="250" height="150" class="mx-auto">
            <v-skeleton-loader
              width="200"
              height="140"
              boilerplate
              type="article, actions"
            ></v-skeleton-loader>
          </v-card>
          <v-chip class="font-weight-bold mt-4" x-small>
            {{ $t("applySignature.signatureConfiguredForNewUsers") }}
          </v-chip>
        </v-col>
      </v-row>

      <p class="text-body-1 mt-6">
        {{ $t("common.actionCanBeUndone") }}
      </p>

      <p
        class="text-body-1"
        v-html="$t('applySignature.confirmModelToNewUsers')"
      />
    </template>
  </HeaderDialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "NewUsersTemplateSignature",
  props: {
    show: { type: Boolean, required: true },
    loading: { type: Boolean, default: false },
    templateId: { type: String, required: true },
  },
  data() {
    return {};
  },
  computed: {
    showDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
  },
  methods: {
    ...mapActions(["setNewUsersTemplate"]),

    saveNewUsersTemplate(templateId) {
      this.setNewUsersTemplate(templateId);
      this.showDialog = false;
    },
  },
};
</script>
