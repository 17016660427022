<template>
  <v-dialog v-model="dialog" width="800" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        outlined
        large
        color="primary"
        :class="`text-none text-body-1 font-weight-bold px-4 ${
          hasInsights ? 'pulse' : ''
        }`"
      >
        <v-icon v-text="'mdi-creation-outline'" left size="24" class="mr-3" />

        <v-badge
          v-if="hasInsights"
          color="accent"
          :content="numberOfInsights"
          class="bounce"
          inline
        >
          Sugestões
        </v-badge>

        <span v-else>Sugestões</span>
      </v-btn>
    </template>

    <v-card
      color="primary"
      class="d-flex flex-column justify-center white--text"
      style="width: 100%; height: 100%"
    >
      <v-row class="ma-0 pa-0" align="center">
        <v-col cols="10" class="pa-0">
          <v-card-title class="text-h5 text-center">
            <span class="mr-2">Sugestões para</span>
            <span class="font-weight-bold"> {{ selectedTemplateName }}</span>
          </v-card-title>
        </v-col>
        <v-col cols="2" class="d-flex justify-end">
          <v-btn icon @click="dialog = false" large>
            <v-icon v-text="'ph-x'" />
          </v-btn>
        </v-col>
      </v-row>

      <v-card-actions class="pb-8 justify-center mt-2">
        <v-progress-circular
          :value="score"
          height="40"
          width="20"
          size="150"
          color="white"
          striped
          class="rounded-pill"
        >
          <span class="text-h4 font-weight-bold">
            {{ score.toFixed(0) }}
          </span>
          <span class="ml-1 mt-4"> % </span>
        </v-progress-circular>
      </v-card-actions>

      <v-subheader
        class="px-4 text-center font-weight-medium text-body-2 text-sm-body-1 mx-auto white--text"
      >
        A assinatura tem uma pontuação
        <span
          :class="`ml-1 ${scoreTextColor} font-weight-bold text-uppercase white px-2 rounded-lg`"
        >
          {{ scoreText }}
        </span>
      </v-subheader>

      <v-card
        elevation="0"
        class="rounded-t-xl px-4 d-flex flex-column justify-center align-center mt-4"
      >
        <v-expansion-panels flat tile mandatory class="py-0">
          <!-- TAGS -->
          <RecommendedTagsPanel
            :has-signature-warnings="!!externalImagesNumber"
          />

          <!-- DIAGNÓSTICOS -->
          <SignatureWarningsPanel />
        </v-expansion-panels>
      </v-card>
    </v-card>
  </v-dialog>
</template>
<script>
import { verifyIfHasATag } from "@/helpers/services/tagPreview";

import { hasExternalImage } from "@/helpers/services/images";

import RecommendedTagsPanel from "@/components/sign/signature/auxiliar_components/signature_insights/RecommendedTagsPanel";
import SignatureWarningsPanel from "@/components/sign/signature/auxiliar_components/signature_insights/SignatureWarningsPanel";

import { mapGetters } from "vuex";

export default {
  name: "SignatureInsightsDialog",

  components: { RecommendedTagsPanel, SignatureWarningsPanel },

  data() {
    return {
      dialog: false,

      tags: {
        "[*USER_FULL_NAME*]": "Nome + sobrenome",
      },
    };
  },

  computed: {
    ...mapGetters(["selectedTemplateName", "selectedTemplateNewHtml"]),

    externalImagesNumber() {
      return hasExternalImage(this.templateHtml);
    },

    hasInsights() {
      return (
        this.recommendedTagsMissingNumber > 0 || this.externalImagesNumber > 0
      );
    },

    listOfTips() {
      return {
        "[*USER_FULL_NAME*]": this.verifyIfHasUseNameTag(),
        "[*USER_PHOTO*]": this.verifyIfHasUsePhoto(),
        "[*USER_PHONE*]": this.verifyIfHasUsePhone(),
        "[*USER_POSITION*]": this.verifyIfHasUsePosition(),
        "[*USER_DEPARTAMENT*]": this.verifyIfHasUseDepartament(),
        "[*COMPANY_LOGO_URL*]": this.verifyIfHasCompanyLogo(),
        "[*COMPANY_FULL_ADDRESS*]": this.verifyIfHasCompanyAddress(),
      };
    },

    numberOfInsights() {
      return this.recommendedTagsMissingNumber + this.externalImagesNumber;
    },

    recommendedTagsMissingNumber() {
      return Object.values(this.listOfTips).reduce((acc, tip) => {
        if (!tip) {
          acc += 1;
        }

        return acc;
      }, 0);
    },

    score() {
      const size = Object.values(this.listOfTips).length;
      const truePoints = Object.values(this.listOfTips).reduce((acc, tip) => {
        if (tip) {
          acc += 1;
        }

        return acc;
      }, 0);

      return (truePoints / size) * 100;
    },

    scoreText() {
      if (this.score >= 90) {
        return "Excelente";
      } else if (this.score >= 80) {
        return "Boa";
      } else if (this.score >= 50) {
        return "Razoável";
      } else if (this.score >= 40) {
        return "Ruim";
      } else {
        return "Muito ruim";
      }
    },

    scoreTextColor() {
      if (this.score >= 80) {
        return "success--text";
      } else if (this.score >= 50) {
        return "amber--text text--accent-4";
      } else if (this.score >= 40) {
        return "orange--text text--accent-4";
      } else {
        return "red--text text--accent-4";
      }
    },

    templateHtml() {
      return this.selectedTemplateNewHtml;
    },
  },

  methods: {
    verifyIfHasCompanyAddress() {
      return verifyIfHasATag(this.templateHtml, "[*COMPANY_FULL_ADDRESS*]");
    },

    verifyIfHasCompanyLogo() {
      return verifyIfHasATag(this.templateHtml, "[*COMPANY_LOGO_URL*]");
    },

    verifyIfHasUseDepartament() {
      return verifyIfHasATag(this.templateHtml, "[*USER_DEPARTAMENT*]");
    },

    verifyIfHasUseNameTag() {
      return verifyIfHasATag(this.templateHtml, "[*USER_FULL_NAME*]");
    },

    verifyIfHasUsePhone() {
      return verifyIfHasATag(this.templateHtml, "[*USER_PHONE*]");
    },

    verifyIfHasUsePhoto() {
      return (
        verifyIfHasATag(this.templateHtml, "[*USER_PHOTO*]") ||
        verifyIfHasATag(this.templateHtml, "[*USER_SIGN_PHOTO*]")
      );
    },

    verifyIfHasUsePosition() {
      return verifyIfHasATag(this.templateHtml, "[*USER_POSITION*]");
    },
  },
};
</script>
<style scoped>
.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.bounce {
  animation: bounce 2s ease infinite;
}
@keyframes bounce {
  70% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-15%);
  }
  90% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
